export default function () {
  return `
    <section id="section6motiv" class="section section--bg-beige">
	    <article class="section__inner flex">
		    <h1>Parce que ta motivation ne s’arrêtera pas là</h1>
		    <ul>
			    <li><span>Abonne-toi à un groupe en ligne de <strong>réparation d’objets</strong>. Vive les communautés d’entraide motivées !</span></li>
			    <li><span>Participe à un café répar-o-thon où des bénévoles aident à <strong>réparer les objets brisés</strong>. Suis les activités du <a href="https://www.crelanaudiere.ca/" target="_blank"><abbr title="Conseil régional de l'environnement de Lanaudière">CREL</abbr></a> pour trouver le prochain évènement.</span></li>
			    <li><span><strong>Diminue les achats impulsifs</strong> en prenant d’abord le temps de bien valider le besoin avant de passer à la caisse. Ah… Pierre-Yves McSween serait fier de toi.</span></li>
			    <li><span>Achète de <strong>seconde main</strong> et donne ou vends tout ce dont tu ne te sers plus. Ton portefeuille, ton sous-sol et la planète te remercieront.</span></li>
			    <li><span>Évite le gaspillage alimentaire en <strong>planifiant</strong> mieux tes repas, en cuisinant les restes et en compostant.</span></li>
		    </ul>
	    </article>
    </section>
  `
}