 export default function () {
  return `
    <div id="filtres-toggle" onclick="toggleFilters(event)">
      <svg width="30" height="30" version="1.1" viewBox="0 0 700 700" xmlns="http://www.w3.org/2000/svg">
       <g>
        <path d="m165.9 165.2v28h-79.098c-7.6992 0-14-6.3008-14-14s6.3008-14 14-14z"/>
        <path d="m627.2 179.2c0 7.6992-6.3008 14-14 14h-367.5v-28h367.5c7.6992 0 14 6.3008 14 14z"/>
        <path d="m140 113.4v130.9h130.9v-130.9zm105.7 105.7h-79.801v-80.5h80.5v80.5z"/>
        <path d="m420 336v28h-333.2c-7.6992 0-14-6.3008-14-14s6.3008-14 14-14z"/>
        <path d="m627.2 350c0 7.6992-6.3008 14-14 14h-112.7v-28h112.7c7.6992 0 14 6.3008 14 14z"/>
        <path d="m394.1 284.2v130.9h130.9v-130.9zm106.4 105.7h-80.5v-80.5h80.5z"/>
        <path d="m165.9 506.8v28h-79.098c-7.6992 0-14-6.3008-14-14s6.3008-14 14-14z"/>
        <path d="m627.2 520.8c0 7.6992-6.3008 14-14 14h-367.5v-28h367.5c7.6992 0 14 6.3008 14 14z"/>
        <path d="m140 455.7v130.9h130.9v-130.9zm105.7 105.7h-79.801v-80.5h80.5v80.5z"/>
       </g>
      </svg>
    </div>
    <div id="mapFiltres">
	    <p>Ville</p>
	    <fieldset id="municipaliteFieldset"></fieldset>
	    <p>Sélectionne comment tu veux réduire à la source</p>
	    <fieldset id="typeOffreFieldset"></fieldset>
    </div>
    <div id='map'>
      <p id="noMarkerErrorMsg">Aucun établissement ne correspond aux critères sélectionnés.</p>
    </div>
    <p class="link"><a href="https://arcg.is/194TDO1" target="_blank">Mon commerce n’est pas sur la carte!</a></p>
  `
}