export default function () {
  return `
    <section id="section1Hero" class="section section--bg-beige">
	    <div class="section__inner">
		    <header class="flex"><img class="logo" src="./images/reduis-a-ta-maniere2.png" alt="Réduis à ta manière"></header>
		    <div class="grid">
			    <figure><img class="heroImg" src="../../images/contenants-hero.png" alt='Un sac en toile, une bouteille réutilisable, une tasse tthermos et un contenant de plastique bleu' /></figure>
			    <div class="textBox">
				    <h3>Les produits à usage <span class="mobileLineBreak">unique sont passés de mode.</span></h3> 
				    <h4>Ce qui est chouette, c’est que <br>pour réduire leur utilisation, tu peux <br>vraiment trouver TON style.</h4>
			    </div>
		    </div>
	    </div>
    </section>
  `
}