export default function () {
  const trucs = [
    {
      img : 'Imagetruc01.png',
      alt : 'Un pot à fermeture hermétique et des sacs réutilisables',
      text : 'Prévois des pots et des sacs de différents formats avant de faire tes courses. Tu as déjà <strong>tout ce qu’il faut</strong> chez toi.'
    },{
      img : 'Imagetruc02.png',
      alt : 'Des grains en pot et en sac.',
      text : 'Opte pour les <strong>grands formats</strong> plutôt que pour les portions individuelles.'
    },{
      img : 'Imagetruc03.png',
      alt : 'Des légumes en vrac.',
      text : `Choisis des produits <strong>moins emballés</strong> ou mieux emballés comme privilégier des légumes à l’unité.`
    },{
      img : 'Imagetruc04.png',
      alt : 'Un contenant à lunch réutilisable et une fourchette de pique-nique.',
      text : "<strong>Commande pour emporter ?</strong> Mentionne que tu auras un plat réutilisable et que tu n’auras pas besoin d’ustensiles."
    },{
      img : 'Imagetruc05.png',
      alt : 'Une serviette de table en tissu et un ustensil multi-usage pour le pique-nique',
      text : '<strong>Traine avec toi</strong> des ustensiles durables et une serviette de table en tissu.'
    },{
      img : 'Imagetruc06.png',
      alt : 'Une tasse et une bouteille réutilisables.',
      text : 'Garde toujours avec toi une <strong>tasse et une bouteille réutilisables</strong>. Oui oui, une de chaque, ça suffit.'
    },{
      img : 'Imagetruc07.png',
      alt : 'Une paille',
      text : 'Dis non. <strong>Refuse</strong> la paille, la crémette, le bonbon même pas bon emballé près de la caisse.'
    }
  ];
  
  const parsedData = trucs.map(truc => {
    return `
      <div class="truc">
        <img src="/images/trucs/${truc.img}" alt="${truc.alt}">
        <p>${truc.text}</p>
      </div>
    `
  }).join('');
  
  const result = `
    <section id="section5Reduis" class="section section--bg-rouge">
      <div class="section__inner">
        <h1>Réduis en <span class="xsmallLineBreak">2 temps,</span> <span class="xsmallLineBreak mobileLineBreak">3 contenants</span></h1>
        <div class="flex">
          ${parsedData}
          <a href='/Reduis_a_ta_maniere_Guide.pdf' class='button button--vert' target="_blank" rel="noopener noreferrer">Trouve plus de trucs ici</a>
        </div>
      </div>
    </section>
  `;
  return result;
}



