///////// GLOBAL //////////
// Map dependencies
import { mapboxAPIKey, sanityEndpoint } from './variables/map.variables.js';
import { municipalitesObj, typesEtablissementObj, typesOffreObj } from './taxonomies.variables.js';
import mapModal from './mapModal.html.js';
import mapModalNoData from './mapModalNoData.html.js';
import mapModalNoMap from './mapModalNoMap.html.js';

// Global variables
const hasApiKey = Boolean(mapboxAPIKey);
const hasData = Boolean(sanityEndpoint);
let geoJsonData = {};
let map = null;
let isJSONEndpointResolved = false;
//let isMapMounted = false;
const mapState = {
  _isMapMounted: false,

  get isMapMounted() {
    return this._isMapMounted;
  },

  set isMapMounted(value) {
    this._isMapMounted = value;
    if (this._isMapMounted) {
      map.resize();
    }
  }
};

///////// MAPBOX functionalities //////////
// Fetch GeoJSON data from the backend
async function fetchEmplacements() {
  if (hasData) {
    try {
      const response = await fetch(sanityEndpoint, {
        method: 'GET',
        headers: {
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache'
        }
      });

      if (response.ok) {
        isJSONEndpointResolved = true;
        const fetchedResponse = await response.json();
        geoJsonData = fetchedResponse.result;
      } else {
        isJSONEndpointResolved = false;
        console.error('Error fetching emplacements:', response.statusText);
      }
    } catch (error) {
      isJSONEndpointResolved = false;
      console.error('Error fetching emplacements:', error);
    }
  }
}

// Place mapModal in #modalContent or error message
function appendMapModal(state) {
  const container = document.getElementById('mapAppContainer');
  if (state) {
    container.innerHTML = mapModal();
  } else {
    console.log('Une erreur est survenue avec le endpoint geoJson de Sanity');
    container.innerHTML = mapModalNoData();
    container.style.gridTemplateColumns = "auto";
  }
}

// Show error message if connexion problem with Mapbox
function noMapErrorMessage() {
  console.log('Une erreur est survenue avec Mapbox API)');
  const container = document.getElementById('mapAppContainer');
  if (!mapState.isMapMounted) {
    container.innerHTML = mapModalNoMap();
    container.style.gridTemplateColumns = "auto";
  }
}

// Initialize Mapbox map and associated functionalities
async function initializeMap() {
  await fetchEmplacements();

  if (isJSONEndpointResolved) {
    mapboxgl.accessToken = mapboxAPIKey;
    
    await appendMapModal(true);

    map = new mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/mapbox/streets-v11',
      zoom: 11,
      center: [-73.4223, 45.79],
      maxBounds: [
        [-74.1, 45.4], // Southwest coordinates
        [-72.6, 46.3]  // Northeast coordinates
      ],
      maxZoom: 18
    });
    
    // Add default zoom in/out buttons (NavigationControl)
    const nav = new mapboxgl.NavigationControl();
    map.addControl(nav, 'top-left');

    map.on('load', function () {
      map.addSource('emplacements', {
        type: 'geojson',
        data: geoJsonData,
        cluster: true,
        clusterMaxZoom: 14,
        clusterRadius: 50
      });
    
      addAllMarkers();
      addTooltips();
      generateCheckboxes(municipalitesObj, 'municipaliteFieldset', 'municipalite');
      generateCheckboxes(typesOffreObj, 'typeOffreFieldset', 'typeOffre');
      setupCheckboxFilters();
      mapState.isMapMounted = true;
    });
    
    map.on('error', () => {
      noMapErrorMessage();
    });
    
  } else {
      appendMapModal(false);
  }
}

// Add markers and clusters to the map
function addAllMarkers() {
  
  // Marqueurs image avec la lettre R logo
  map.addLayer({
    id: 'emplacements',
    type: 'symbol',
    source: 'emplacements',
    layout: {
      'icon-image': 'marqueur',
      'icon-size': 0.15,
      'icon-anchor': 'bottom',
      'icon-ignore-placement': true
    }
  });
  
  // Cluster cercles rouges
  map.addLayer({
    id: 'clusters',
    type: 'circle',
    source: 'emplacements',
    filter: ['has', 'point_count'],
    paint: {
      'circle-color': '#cb342a',
      'circle-radius': 20,
      'circle-stroke-color': 'white',
      'circle-stroke-width': 2,
    }
  });
  
  // Cluster chiffre compteur
  map.addLayer({
    id: 'cluster-count',
    type: 'symbol',
    source: 'emplacements',
    filter: ['has', 'point_count'],
    layout: {
      'text-field': ['get', 'point_count_abbreviated'],
      'text-font': ['Arial Unicode MS Bold'],
      'text-size': 18,
    },
    paint: {
      'text-color': "#fff",
    }
  });
  
  // Zoom event handler when cluster is clicked
  map.on('click', 'clusters', (e) => {
    const features = map.queryRenderedFeatures(e.point, {
      layers: ['clusters']
    });
    const clusterId = features[0].properties.cluster_id;
    map.getSource('emplacements').getClusterExpansionZoom(
      clusterId,
      (err, zoom) => {
        if (err) return;
         
        map.easeTo({
          center: features[0].geometry.coordinates,
          zoom: zoom
        });
      }
    );
  });
  
  map.loadImage('./images/marqueurTemp-2.png', function(error, image) {
    if (error) throw error;
    map.addImage('marqueur', image);
  });
}

// Add tooltips to markers
function addTooltips() {
  map.on('click', 'emplacements', function (e) {
    const coordinates = e.features[0].geometry.coordinates.slice();
    const nom = e.features[0].properties.nom;
    const adresse = e.features[0].properties.adresse;
    const sitewebRaw = e.features[0].properties.website;
    const tel = e.features[0].properties.phoneNumber;
    const municipalite = municipalitesObj[e.features[0].properties.municipalite];
    const typeOffreRaw = JSON.parse(e.features[0].properties.typeOffre);
    
    const currentTypeOffreObj = typeOffreRaw.map((catRaw, index) => ({
        catRaw,
        catName: typesOffreObj[catRaw]
    })).filter(type => type.catName !== undefined);
    
    let typeOffreListe = '';
    
    currentTypeOffreObj.forEach(type => {
      const liElement = document.createElement('li');
      liElement.classList.add(type.catRaw);
      liElement.innerText = type.catName;
      typeOffreListe += liElement.outerHTML;
    });
    
    // Build telephone div if it exists
    let telDiv = '';
    if (tel) {
      telDiv = `<div class="emplacement_tel">${tel}</div>`;
    };
    
    // Build site web link if it exists
    let sitewebLink = '';
    if (sitewebRaw) {
      const sitewebNice = sitewebRaw.replace(/^(https?:\/\/(www\.)?)?(facebook\.com\/)?/, '');
      sitewebLink = `<a class="emplacement_url" target="_blank" href="${sitewebRaw}">${sitewebNice}</a>`;
    };
    
    const tooltipText = 
      `<div class="emplacement">
        <div>
          <div class="emplacement_nom">${nom}</div>
          <div class="emplacement_adresse">${adresse}, <br> ${municipalite}</div>
        </div>
        ${telDiv}
        ${sitewebLink}
        <ul class="emplacement_offres">${typeOffreListe}</ul>
      </div>`;
    
    while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
      coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
    }
    new mapboxgl.Popup()
      .setLngLat(coordinates)
      .setHTML(tooltipText)
      .addTo(map);
  });
  map.on('mouseenter', 'emplacements', function () {
    map.getCanvas().style.cursor = 'pointer';
  });
  map.on('mouseleave', 'emplacements', function () {
    map.getCanvas().style.cursor = '';
  });
}

// Set up checkbox filters for the map markers
function setupCheckboxFilters() {
  const checkboxes = document.querySelectorAll('input[type="checkbox"]');

  checkboxes.forEach(checkbox => {
    checkbox.addEventListener('change', updateMarkers);
  });
}

///////// TAXONOMY & FILTERS functionalities //////////
// Generate checkboxes for a given taxonomy
function generateCheckboxes(taxonomyMappingObj, fieldsetId, taxonomyName) {
  const fieldset = document.getElementById(fieldsetId);

  Object.entries(taxonomyMappingObj).forEach(([value, categoryName]) => {
    const checkbox = createCheckbox(value, categoryName, taxonomyName);
    fieldset.appendChild(checkbox);
  });
}

// Create a checkbox element
function createCheckbox(value, categoryName, taxonomyName) {
  const label = document.createElement('label');

  const checkbox = document.createElement('input');
  checkbox.type = 'checkbox';
  checkbox.name = taxonomyName;
  checkbox.value = value;
  checkbox.checked = true; // All checkboxes checked by default

  label.appendChild(checkbox);
  label.appendChild(document.createTextNode(categoryName));

  return label;
}

// UX : Désactiver le click sur le dernier élément coché dans les fieldsets
// puis le rétablir lorsque qu'un second élément est coché
function handleLastCheckedInput (array) {
  let checkedCount = 0;
  let selectedElement = null;
  array.forEach(input => {
      if (input.checked) {
          checkedCount++;
          selectedElement = input;
      }
  });
  if (checkedCount === 1) {
      selectedElement.setAttribute("disabled", "");
  } else {
      array.forEach(input => {
        input.removeAttribute("disabled");
      });
  }
}

// Update markers based on selected checkboxes
function updateMarkers(event) {
  // Variables
  const checkboxes = document.querySelectorAll('input[type="checkbox"]');
  const selectedValues = {
    municipalite: [],
    typeOffre: [],
  };
  const changedCheckbox = event.target;
  const casesMunicipalite = Array.from(checkboxes).filter(checkbox => {
    return checkbox.getAttribute('name') === 'municipalite' && checkbox.getAttribute('value') !== 'toutes'
  });
  const casesTypeOffre = Array.from(checkboxes).filter(checkbox => {
    return checkbox.getAttribute('name') === 'typeOffre'
  });
  const caseToutes = Array.from(checkboxes).find(checkbox => {
    return checkbox.getAttribute('name') === 'municipalite' && checkbox.getAttribute('value') === 'toutes'
  });
  const popups = document.querySelectorAll('.mapboxgl-popup');
  const errorMsgElement = document.getElementById('noMarkerErrorMsg');
  
  // Remove all popups from the map
  popups.forEach(popup => popup.remove());
  
  // if Toutes option is checked or unchecked, adapt other checkboxes accordingly
  if (changedCheckbox === caseToutes) {
    for (let i = 0; i < casesMunicipalite.length; i++) {
      casesMunicipalite[i].checked = changedCheckbox.checked;
    }
  };
  
  // Build the selected options arrays from the selected checkboxes
  checkboxes.forEach(checkbox => {
    if (checkbox.checked) {
      selectedValues[checkbox.name].push(checkbox.value);
    }
  });
  
  // UX : Unselect "Toutes" when at least one other option is unchecked
  const atLeastOneUnchecked = Array.from(casesMunicipalite).some(checkbox => !checkbox.checked);
  if (atLeastOneUnchecked) {
    caseToutes.checked = false;
  };
  
  // UX : Désactiver le click sur le dernier élément coché dans les fieldsets
  handleLastCheckedInput(casesMunicipalite);
  handleLastCheckedInput(casesTypeOffre);
    
  // UX : Select "Toutes" when every other options are checked
  const allMunicipalitesChecked = casesMunicipalite.every(checkbox => {
    return checkbox.checked ;
  });
  if (allMunicipalitesChecked) {
    caseToutes.checked = true;
  };
  
  // Map the selected values with the marker items on the map
  const filteredFeatures = geoJsonData.features.filter(feature => {
    const props = feature.properties;
    const isInMunicipalite = selectedValues.municipalite.includes(props.municipalite);
    const isInTypeOffre = props.typeOffre.some(offre => selectedValues.typeOffre.includes(offre));
    return isInMunicipalite && isInTypeOffre;  
  });
  
  // Check if there are no selected markers
  if (filteredFeatures.length === 0) {
    console.error("No markers match the selected criteria.");
    // Remove all markers from the map and show error message
    map.getSource('emplacements').setData({
      type: 'FeatureCollection',
      features: []
    });
    errorMsgElement.style.display = 'block';
  } else {
    // Show only the selected items on the map and hide error message
    map.getSource('emplacements').setData({
      type: 'FeatureCollection',
      features: filteredFeatures,
    });
    errorMsgElement.style.display = 'none';
    
    // Extract coordinates of selected markers
    const selectedMarkerCoordinates = filteredFeatures.map(feature => feature.geometry.coordinates);
    
    // Calculate the bounds of selected markers
    const bounds = selectedMarkerCoordinates.reduce((bounds, coord) => {
      return bounds.extend(coord);
    }, new mapboxgl.LngLatBounds(selectedMarkerCoordinates[0], selectedMarkerCoordinates[0]));
    
    // Set the map's viewport to the bounds of selected markers
    map.fitBounds(bounds, {
      padding: 80,
      maxZoom: 15
    });
  }
  
};

///////// MODAL functionality //////////
document.addEventListener('DOMContentLoaded', function() {
  const toggleModal = () => {
    const modal = document.querySelector('.modal');
    const openBtn = document.querySelectorAll('a.open');
    const closeBtn = document.querySelector('.close');
    const modalContent = document.querySelector('.modal-content');
    const urlParams = new URLSearchParams(window.location.search);
      
    if ( openBtn ) {
      openBtn.forEach( button => {
        button.onclick = function(event) {
        modal.style.display = 'block';
        if (mapState.isMapMounted) { map.resize(); };
        urlParams.set('carte', 'ouverte');
        window.history.replaceState({}, '', `${window.location.pathname}?${urlParams}`);
        event.preventDefault();
        }
      });
    }
    
    closeBtn.onclick = function() {
      modal.style.display = 'none';
      urlParams.delete('carte');
      window.history.replaceState({}, '', `${window.location.pathname}?${urlParams}`);
    }
    
    window.onclick = function(event) {
      const theOpenModal = document.querySelector('.modal[style="display: block;"');
      if ( event.target == theOpenModal ) {
        theOpenModal.style.display = "none";
        urlParams.delete('carte');
        window.history.replaceState({}, '', `${window.location.pathname}?${urlParams}`);
      }
    }
  }
  toggleModal();

  // Show modal if the URL parameter indicates so
  async function verifyModalState() {
    return new Promise((resolve) => {
      window.onload = function() {
        const urlParams = new URLSearchParams(window.location.search);
        const modalState = urlParams.get('carte');
  
        if (modalState === 'ouverte') {
          const theOpenModal = document.querySelector('.modal');
          theOpenModal.style.display = "block";
        }
        resolve(); // Resolve the promise after handling modal state
      };
    });
  }
  
  async function resizeMapAfterModalOpens() {
    await verifyModalState(); // Wait for the modal state verification to complete
    // Now check if the map is mounted and resize if necessary
    if (mapState.isMapMounted) { map.resize(); }
  }
  resizeMapAfterModalOpens();

});

// Check if API key is available and start map initialization
if (hasApiKey && hasData) {
  initializeMap();
} else {
  console.log('Erreur avec les variables mapboxAPIKey ou sanityEndpoint' );
}

// TOGGLE FILLTERS on mobile screen
const toggleFilters = (event) => {
  const toggleButton = event.currentTarget;
  const filtres = document.getElementById("mapFiltres");

  toggleButton.classList.toggle("filtres-visibles");
  filtres.classList.toggle("filtres-visibles");
}
window.toggleFilters = toggleFilters; // puts the function in the global scope for webpack