export default function () {

  return `
    <div class="section__inner">
      <a class="mrclogo" href="https://www.mrclassomption.qc.ca/" title="Aller sur le site principal de la MRC de L'Assomption" target="_blank">
        <svg width="200" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 234.32 73.77" alt="MRC de L'Assomption">
          <g>
            <g>
              <path class="cls-1" d="M76.19,32.97v31.8c0,3.3-2.67,5.97-5.97,5.97h0c-3.3,0-5.97-2.67-5.97-5.97v-31.99c0-5.3-3.99-9.93-9.27-10.28-5.8-.39-10.64,4.22-10.64,9.93v5.47c0,3.45-2.79,6.24-6.24,6.24h0c-3.44,0-6.24-2.79-6.24-6.24v-5.12c0-5.3-3.99-9.93-9.27-10.28-5.8-.39-10.64,4.22-10.64,9.93v11.17H0v-10.63C0,20.79,9.96,10.4,22.13,10.53c6.32,.06,12.01,2.82,15.97,7.17,3.96-4.35,9.65-7.11,15.97-7.17,12.17-.12,22.13,10.27,22.13,22.44Zm42.2,26.12c-2.43,0-4.72-.31-9.26-4.74-1.89-1.84-6.35-6.33-10.59-10.62,9.91-2.2,17.13-11.15,17.14-21.76C115.68,9.82,105.56-.11,93.27,.02h-8.48c-3.3,.02-5.97,2.63-5.97,5.93v.47c0,3.3,2.67,5.51,5.97,5.53h9.05c5.3-.02,9.87,4.45,9.87,9.72,0,5.81-4.87,10.24-10.58,10.19h-8.2c-1.96,.05-3.87,.92-5.01,2.52-1.75,2.44-1.42,6.06,.58,8.1,.66,.67,16.15,16.39,20.28,20.42,7.4,7.23,12.97,7.83,17.61,7.83v-11.64Zm33.37-27.23h-10.54c-5.3,0-9.93-3.99-10.28-9.27-.39-5.8,4.22-10.64,9.93-10.64h10.89V0h-10.35c-11.86,0-21.97,9.2-22.42,21.06-.47,12.46,9.53,22.75,21.89,22.75h10.89v-11.95Z"/>
              <g>
                <path class="cls-1" d="M130.74,69.95c-.43-.36-.76-.86-1-1.53-.24-.66-.36-1.45-.36-2.36,0-1.37,.28-2.44,.84-3.22,.56-.78,1.32-1.17,2.28-1.17,.67,0,1.25,.21,1.73,.62v-3.82c0-.31,.26-.46,.79-.46h.3c.53,0,.79,.15,.79,.46v9.17c0,.74,.03,1.29,.09,1.63,.06,.34,.09,.56,.09,.63,0,.15-.16,.27-.49,.35-.33,.08-.62,.12-.88,.12-.16,0-.27-.08-.34-.24-.07-.16-.12-.33-.16-.52-.03-.19-.06-.31-.07-.38-.24,.38-.54,.68-.92,.9-.37,.22-.78,.33-1.22,.33-.56,0-1.05-.18-1.48-.53Zm3.5-2.21v-3.94c-.4-.31-.84-.46-1.31-.46-1.08,0-1.61,.9-1.61,2.69,0,.9,.12,1.58,.36,2.04,.24,.46,.6,.7,1.08,.7,.55,0,1.05-.34,1.49-1.03Z"/>
                <path class="cls-1" d="M138.73,69.36c-.63-.75-.94-1.82-.94-3.23,0-1.51,.31-2.63,.93-3.36,.62-.73,1.5-1.1,2.64-1.1,1.08,0,1.87,.38,2.4,1.15,.52,.77,.78,1.8,.78,3.1,0,.12-.09,.29-.27,.52-.18,.22-.31,.34-.4,.34h-4.12c.05,.72,.23,1.24,.54,1.57,.31,.32,.77,.48,1.37,.48,.54,0,1.09-.15,1.64-.46,.16-.08,.26-.11,.3-.11,.13,0,.28,.15,.44,.44,.16,.29,.25,.51,.25,.66,0,.21-.15,.4-.43,.57-.29,.18-.64,.31-1.07,.41-.42,.1-.82,.15-1.19,.15-1.28,0-2.24-.37-2.87-1.12Zm3.94-4.05c0-.62-.11-1.11-.34-1.46-.22-.35-.56-.53-.99-.53-.93,0-1.46,.66-1.6,1.99h2.93Z"/>
                <path class="cls-1" d="M149.98,70.22c-.09-.1-.14-.25-.14-.46v-10.44c0-.32,.26-.48,.79-.48h.37c.28,0,.48,.04,.6,.11,.12,.08,.19,.2,.19,.36v9.21h3.54c.14,0,.24,.06,.3,.17s.09,.29,.09,.53v.43c0,.24-.03,.42-.09,.53s-.16,.17-.3,.17h-4.94c-.19,0-.33-.05-.42-.15Z"/>
                <path class="cls-1" d="M156.01,62.15c-.12-.05-.18-.14-.18-.25,0-.09,.03-.24,.11-.44,.16-.4,.29-.82,.39-1.25,.1-.43,.15-.96,.15-1.59,0-.21,.06-.37,.19-.48,.13-.11,.33-.17,.6-.17h.24c.26,0,.46,.06,.59,.17,.13,.11,.2,.27,.2,.45,0,.59-.11,1.17-.33,1.73s-.48,1.02-.78,1.38c-.3,.36-.56,.53-.77,.53-.15,0-.28-.03-.4-.08Z"/>
                <path class="cls-1" d="M158.19,70.08s.02-.13,.06-.28l3.38-10.64c.06-.21,.31-.31,.76-.31h.66c.44,0,.69,.1,.76,.31l3.38,10.64c.03,.11,.04,.2,.04,.28,0,.22-.24,.33-.72,.33h-.46c-.44,0-.69-.1-.76-.31l-.69-2.25h-3.82l-.69,2.25c-.07,.21-.29,.31-.66,.31h-.42c-.55,0-.82-.11-.82-.33Zm5.84-4.07l-1.09-3.61c-.08-.25-.15-.59-.21-1.02h-.06c0,.07-.03,.21-.07,.43s-.09,.42-.15,.59l-1.12,3.61h2.7Z"/>
                <path class="cls-1" d="M169.29,70.36c-.38-.1-.69-.23-.93-.4-.24-.17-.36-.35-.36-.53s.08-.42,.25-.7c.17-.28,.32-.42,.45-.42,.04,0,.15,.05,.33,.16s.37,.19,.57,.27c.2,.08,.44,.11,.69,.11,.72,0,1.08-.31,1.08-.92,0-.3-.11-.53-.32-.69-.21-.16-.56-.34-1.04-.53-.59-.23-1.04-.53-1.36-.89-.32-.36-.48-.89-.48-1.58,0-.79,.24-1.42,.72-1.89,.48-.47,1.12-.71,1.92-.71,.52,0,1.01,.08,1.46,.24,.46,.16,.69,.37,.69,.63,0,.19-.07,.42-.21,.71-.14,.29-.27,.44-.39,.44-.02,0-.16-.06-.4-.18-.25-.12-.55-.18-.9-.18-.3,0-.54,.06-.72,.19-.18,.13-.27,.3-.27,.53s.06,.42,.19,.57c.13,.15,.28,.27,.44,.36,.16,.09,.46,.22,.87,.41,.58,.25,1,.55,1.27,.9,.27,.35,.4,.85,.4,1.51,0,.81-.26,1.46-.77,1.96-.51,.5-1.2,.75-2.05,.75-.4,0-.79-.05-1.16-.15Z"/>
                <path class="cls-1" d="M175.7,70.36c-.38-.1-.69-.23-.93-.4-.24-.17-.36-.35-.36-.53s.08-.42,.25-.7c.17-.28,.32-.42,.45-.42,.04,0,.15,.05,.33,.16s.37,.19,.57,.27c.2,.08,.44,.11,.69,.11,.72,0,1.08-.31,1.08-.92,0-.3-.11-.53-.32-.69-.21-.16-.56-.34-1.04-.53-.59-.23-1.04-.53-1.36-.89-.32-.36-.48-.89-.48-1.58,0-.79,.24-1.42,.72-1.89,.48-.47,1.12-.71,1.92-.71,.52,0,1.01,.08,1.46,.24,.46,.16,.69,.37,.69,.63,0,.19-.07,.42-.21,.71-.14,.29-.27,.44-.39,.44-.02,0-.16-.06-.4-.18-.25-.12-.55-.18-.9-.18-.3,0-.54,.06-.72,.19-.18,.13-.27,.3-.27,.53s.06,.42,.19,.57c.13,.15,.28,.27,.44,.36,.16,.09,.46,.22,.87,.41,.58,.25,1,.55,1.27,.9,.27,.35,.4,.85,.4,1.51,0,.81-.26,1.46-.77,1.96-.51,.5-1.2,.75-2.05,.75-.4,0-.79-.05-1.16-.15Z"/>
                <path class="cls-1" d="M181.66,69.35c-.61-.76-.91-1.84-.91-3.27s.3-2.53,.91-3.28c.61-.75,1.49-1.13,2.66-1.13s2.03,.38,2.64,1.13c.6,.76,.9,1.85,.9,3.28s-.3,2.52-.9,3.27c-.6,.75-1.48,1.12-2.64,1.12s-2.05-.38-2.66-1.13Zm3.88-1.21c.26-.45,.4-1.15,.4-2.08s-.13-1.61-.4-2.06c-.26-.45-.67-.68-1.23-.68s-.97,.23-1.24,.68c-.27,.45-.4,1.14-.4,2.06s.13,1.62,.4,2.08c.27,.45,.68,.68,1.24,.68s.97-.23,1.23-.68Z"/>
                <path class="cls-1" d="M200.11,64.33v5.6c0,.32-.26,.48-.79,.48h-.3c-.53,0-.79-.16-.79-.48v-5.32c0-.81-.36-1.21-1.08-1.21-.32,0-.6,.11-.84,.32-.24,.21-.43,.45-.57,.7v5.51c0,.32-.26,.48-.79,.48h-.28c-.28,0-.48-.04-.6-.11-.12-.08-.19-.2-.19-.36v-5.32c0-.81-.36-1.21-1.07-1.21-.34,0-.63,.12-.88,.35-.25,.24-.42,.46-.52,.66v5.51c0,.32-.26,.48-.79,.48h-.3c-.53,0-.79-.16-.79-.48v-5.38c0-.73-.03-1.27-.09-1.61-.06-.34-.09-.55-.09-.62,0-.15,.16-.27,.49-.35,.33-.08,.62-.12,.88-.12,.16,0,.27,.08,.34,.23,.07,.15,.12,.31,.16,.48,.03,.17,.06,.29,.07,.35,.26-.35,.58-.64,.98-.88,.39-.24,.81-.35,1.26-.35,1.05,0,1.72,.43,2.02,1.28,.27-.36,.61-.66,1.02-.91,.41-.25,.85-.37,1.31-.37,1.49,0,2.24,.89,2.24,2.66Z"/>
                <path class="cls-1" d="M202.41,73.65c-.12-.08-.19-.2-.19-.36v-8.75c0-.73-.03-1.27-.09-1.61-.06-.34-.09-.55-.09-.62,0-.15,.16-.27,.49-.35,.33-.08,.62-.12,.87-.12,.17,0,.29,.08,.36,.23,.07,.15,.12,.31,.16,.48s.06,.29,.07,.35c.25-.38,.55-.68,.92-.9,.36-.22,.76-.33,1.2-.33,.57,0,1.07,.18,1.49,.53,.43,.36,.76,.87,.99,1.53,.23,.67,.35,1.45,.35,2.35,0,1.37-.28,2.44-.83,3.23-.55,.78-1.31,1.17-2.28,1.17-.66,0-1.24-.21-1.75-.62v3.43c0,.32-.26,.48-.79,.48h-.28c-.28,0-.48-.04-.6-.11Zm4.61-7.53c0-.9-.12-1.58-.36-2.04-.24-.46-.59-.7-1.06-.7-.29,0-.56,.09-.81,.28-.25,.19-.49,.44-.69,.75v3.94c.4,.31,.84,.46,1.33,.46,1.07,0,1.6-.9,1.6-2.69Z"/>
                <path class="cls-1" d="M211.46,69.88c-.38-.4-.57-1.04-.57-1.9v-4.53h-.4c-.14,0-.24-.05-.31-.16-.06-.11-.1-.29-.1-.54v-.25c0-.25,.03-.43,.1-.54,.06-.11,.17-.16,.31-.16h.4l.19-1.61c.05-.32,.28-.48,.69-.48h.36c.43,0,.64,.16,.64,.48v1.61h.91c.14,0,.24,.05,.31,.16,.06,.11,.1,.29,.1,.54v.25c0,.25-.03,.43-.1,.54-.07,.11-.17,.16-.31,.16h-.91v4.5c0,.33,.05,.56,.16,.69,.11,.13,.26,.2,.47,.2,.1,0,.19-.01,.28-.03,.09-.02,.16-.03,.21-.03,.12,0,.22,.14,.3,.42s.12,.48,.12,.62c0,.46-.44,.69-1.31,.69-.64,0-1.15-.2-1.53-.61Z"/>
                <path class="cls-1" d="M215.66,60.02v-1.07c0-.36,.32-.54,.97-.54s.94,.18,.94,.54v1.07c0,.19-.08,.32-.23,.41-.16,.09-.4,.13-.74,.13-.63,0-.94-.18-.94-.54Zm.01,9.91v-7.7c0-.32,.26-.48,.79-.48h.3c.53,0,.79,.16,.79,.48v7.7c0,.32-.26,.48-.79,.48h-.3c-.53,0-.79-.16-.79-.48Z"/>
                <path class="cls-1" d="M220.14,69.35c-.61-.76-.91-1.84-.91-3.27s.3-2.53,.91-3.28c.61-.75,1.49-1.13,2.66-1.13s2.03,.38,2.64,1.13c.6,.76,.9,1.85,.9,3.28s-.3,2.52-.9,3.27c-.6,.75-1.48,1.12-2.64,1.12s-2.05-.38-2.66-1.13Zm3.88-1.21c.26-.45,.4-1.15,.4-2.08s-.13-1.61-.4-2.06c-.26-.45-.67-.68-1.23-.68s-.97,.23-1.24,.68c-.27,.45-.4,1.14-.4,2.06s.13,1.62,.4,2.08c.27,.45,.68,.68,1.24,.68s.97-.23,1.23-.68Z"/>
                <path class="cls-1" d="M228.01,69.93v-5.38c0-.73-.03-1.27-.09-1.61-.06-.34-.09-.55-.09-.62,0-.15,.16-.27,.49-.35,.33-.08,.62-.12,.88-.12,.17,0,.29,.08,.36,.24,.07,.16,.12,.32,.16,.49s.06,.28,.07,.34c.26-.36,.59-.66,.99-.89s.82-.34,1.26-.34c1.51,0,2.27,.89,2.27,2.66v5.6c0,.16-.06,.28-.19,.36-.12,.08-.33,.11-.6,.11h-.28c-.53,0-.79-.16-.79-.48v-5.32c0-.81-.37-1.21-1.12-1.21-.29,0-.56,.1-.81,.3-.25,.2-.46,.44-.62,.72v5.51c0,.32-.26,.48-.79,.48h-.3c-.53,0-.79-.16-.79-.48Z"/>
              </g>
            </g>
          </g>
        </svg>
      </a>
      <nav><a href="#?carte=ouverte" class="open button">Carte</a></nav>
    </div>
  
`}