export default function () {
  return `
    <section id="section4Territoire" class="section section--bg-gris textCentered">
	    <div class="section__inner grid">
		    <h2>Le territoire en action</h2>
		    <figure>
          <a href="#?carte=ouverte" class="open" aria-roledescription="Ouvrir la carte pour la consulter">
            <img src="/images/section4-carte2.png" alt="Image de carte géographique de la région de la MRC L'Assomption" />
          </a>
        </figure>
		    <a href="#?carte=ouverte" class="open button button--vert">Consulte la carte</a>
	    </div>
    </section>
  `
}