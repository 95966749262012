export default function () {
  return `
    <section id="section3Gene" class="section section--bg-rose">
	    <div class="section__inner flex">
		    <div class="textBox grid">
			    <h1>Y’a pas de gêne</h1>
			    <p>Les épiceries, les restaurants, les cafés et les comptoirs de repas à emporter sont prêts à vous accueillir, toi et tes contenants.</p>
			    <p>La MRC les a déjà rencontrés pour jaser de réduction à la source. Que tu arrives avec ton pot Mason <em>cute</em> ou ton vieux pot de yogourt, il y aura zéro malaise, promis !</p>
		    </div>
        <figure>
          <img src="/images/section3.png" alt="Une cliente apporte un pot réutilisable chez un commerçant" />
        </figure>
	    </div>
    </section>
  `
}