import './styles/normalize.css';
import './styles/global.scss';

import siteHeader from './views/siteHeader/siteHeader.html.js';
import './views/siteHeader/siteHeader.scss';

import section1Hero from './views/section1Hero/section1Hero.html.js';
import './views/section1Hero/section1Hero.scss';

import section2Cestquoi from './views/section2Cestquoi/section2Cestquoi.html.js';
import './views/section2Cestquoi/section2Cestquoi.scss';
import './views/section2Cestquoi/section2Cestquoi.js';

import section3Gene from './views/section3Gene/section3Gene.html.js';
import './views/section3Gene/section3Gene.scss';

import section4Territoire from './views/section4Territoire/section4Territoire.html.js';
import './views/section4Territoire/section4Territoire.scss';

import section5Reduis from './views/section5Reduis/section5Reduis.html.js';
import './views/section5Reduis/section5Reduis.scss';

import section6motiv from './views/section6motiv/section6motiv.html.js';
import './views/section6motiv/section6motiv.scss';

import section7Tendance from './views/section7Tendance/section7Tendance.html.js';
import './views/section7Tendance/section7Tendance.scss';

import './views/mapModal/mapModal.js';
import './views/mapModal/mapModal.scss';

import siteFooter from './views/siteFooter/siteFooter.html.js';
import './views/siteFooter/siteFooter.scss';

// Place siteHeader 
document.getElementById('siteHeader').innerHTML = siteHeader();

// Place page Sections in #siteMain
function appendSections() {
  const container = document.getElementById('siteMain');
  const sections = [
    section1Hero,
    section2Cestquoi,
    section3Gene,
    section4Territoire,
    section5Reduis,
    section6motiv,
    section7Tendance
  ];
  sections.forEach(section => container.innerHTML += section() );
}
appendSections();

// Place siteFooter 
document.getElementById('siteFooter').innerHTML = siteFooter();

