export default function () {
  return `
    <section id="section2Cestquoi"  class="section section--bg-vert textCentered section--bannerEtroite">
	    <div class="section__inner">
		    <h4>C’est quoi un produit à usage unique ?</h4>
		    <div class="textTwoLinesEqualWidth">
			    <h3>Un produit, un contenant ou un emballage ayant une très courte durée de vie avant d’être jeté.</h3>
		    </div>
		    <div>
			    <figure class="flex">
            <img src="/images/section2-contenantsA.png" alt="Un contenant en styromousse, une bouteille de plastique et une cuillère de plastique" /> <img src="/images/section2-contenantsB.png" alt="Un sac de plastique et une tasse à café jetable"/>
          </figure>
		    </div>
	    </div>
    </section>
  `
}