// Constantes pour les taxonomies

const municipalitesObj = {
  'toutes': 'Toutes',
  'charlemagne': 'Charlemagne',
  'lassomption': 'L’Assomption',
  'lepiphanie': 'L’Épiphanie',
  'repentigny': 'Repentigny',
  'saintsulpice': 'Saint-Sulpice'
};

const typesOffreObj = {
  'contenants': 'Contenants réutilisables acceptés',
  'vrac': 'Produits en vrac disponibles',
  'location': 'Friperie, service de location et de réparation',
  'locaux': 'Mise en valeur des produits de la région',
  'ecoresponsables': 'Produits écoresponsables disponibles',
  'autre_offre': 'Autres mesures'
};

export { municipalitesObj, typesOffreObj};