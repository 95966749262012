document.addEventListener('DOMContentLoaded', function() {
	// // Display utilities // // //
	// textTwoLinesEqualWidth
	function splitTextIntoLines() {
  	const textContainer = document.querySelector('.textTwoLinesEqualWidth');
  	const containerWidth = textContainer.offsetWidth;
  	const textToSplit = document.querySelector('.textTwoLinesEqualWidth > *');
  	const text = textToSplit.textContent.trim();
  	const words = text.split(' ');
  	const wordCount = words.length;
    	
  	if (containerWidth > 1050) {
    	// Calculate the ideal number of words per line
    	const idealWordsPerLine = Math.ceil(wordCount / 1.75);
    	
    	// Split the words into two lines
    	const line1 = words.slice(0, idealWordsPerLine).join(' ');
    	const line2 = words.slice(idealWordsPerLine).join(' ');
    	
    	// Update the text content of the span elements
    	textToSplit.innerHTML = `<div>${line1}</div> <div>${line2}</div>`;
  	}
	}
  	
	// Call the function after the page loads
	window.addEventListener('load', splitTextIntoLines);
});