export default function () {
  
  const faits = [
    {
      pourcent : 95,
      texte : `affirment avoir déjà réfléchi à la consommation responsable et au gaspillage des ressources`
    },{
    pourcent : 96,
      texte : `donnent ou vendent des objets usagés`
    },{
    pourcent : 75,
      texte : `se questionnent avant de faire un achat`
    },{
    pourcent : 91,
      texte : `privilégient leur bouteille réutilisable à l’eau embouteillée`
    }
  ]
  
  const parsedData = faits.map(fait => {
    return `
      <div class="fait">
        <div class="pourcent">${fait.pourcent}&nbsp;%</div>
        <p>${fait.texte}</p>
      </div>
    `
  }).join('');
  
  return `
    <section id="section7Tendance" class="section section--bg-vert section--bannerEtroite">
      <div class="section__inner">
	      <div class="textCentered">
		      <h2>La tendance gagne du terrain</h2>
		      <h3>Sondée sur ses pratiques écoresponsables, la population de la MRC a scoré fort.</h3>
	      </div>
	      <div class="grid">
		      ${parsedData}
	      </div>
        <div class="ellipse"></div>
        <h4 class="textCentered"><span>Wow!</span><br>Ça en fait du monde, tu peux te péter les bretelles !</h4>
      </div>
    </section>
`}